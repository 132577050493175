import React, { useState, useContext, Component, createRef } from "react";
import pincode from "pincode-distance/lib/pincode";
import { Country, State, City } from "country-state-city";
import CartContext from "./CartContext";
import "../CSS/3d.css";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { sha256 } from "js-sha256";
import { OfflineStore } from "./OfflineStore";
import IncDecCounter from "./IncDecCounterControl";

import {
  Modal,
  Nav,
  Form,
  FloatingLabel,
  Table,
  Container,
  Row,
  Col,
  Button,
  Stack,
} from "react-bootstrap";

import CheckoutAndPayForm from "./CheckoutAndPayForm";

export default class PayView extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      EmailId: "",
      QuotationNumber: "",
      CategoryName: "",
      ProductName: "",
      Qty: "",
      Amount: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      Name:
        this.context.userData.firstName == null
          ? ""
          : this.context.userData.firstName +
              " " +
              this.context.userData.lastName ==
            null
          ? ""
          : this.context.userData.lastName,
    });
    this.setState({ EmailId: this.context.userData.emailId });
  }

  componentDidUpdate = () => {
    if (this.context.refreshIncDecControl == true) {
      this.context.SetRefreshIncDecControl(false);
      this.setState({ reload: true }, () => {
        this.setState({ reload: false });
      });
    }
  };
  refresh = () => {};
  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    return (
      <>
        <div className="Container-fluid mx-0 px-0 checkout-container">
          <div className="row mt-1 mx-0 px-0 inner-row">
            <Helmet>
              <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
              <link href="dist/css/grnstyle.css" rel="stylesheet" />
              {/* <link href="dist/css/grnresponsive.css" rel="stylesheet" /> */}
              <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
              {/* <script src="dist/js/grncustom.js"></script> */}
            </Helmet>

            <Row>
              <Col xs={5} md={2} className="">
                <div class="">
                  <h2 className=" ectittle-black-outline-5">Checkout</h2>
                </div>
              </Col>
              <Col xs={5} md={7} className="">
                <br />
                <br />
                <br />

                <div className="px-1 w-100 border">
                  <div
                    className="m-0 p-0 w-100 border-0 text-center"
                    style={{ backgroundColor: "darkblue", color: "white" }}
                  >
                    <h1>CHECKOUT</h1>
                  </div>
                  <Form style={{ fontSize: "18px", fontWeight: "bold" }}>
                    <Row className="mx-2 p-3 bg-white border-0 ">
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Enter Name"
                            value={this.state.Name}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label>Mobile Number</Form.Label>
                          <Form.Control
                            type="text"
                            size="lg"
                            // placeholder="Enter Mobile Number"
                            disabled
                            value={this.context.userData.mobileNo}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            size="lg"
                            placeholder="Enter Email Id"
                            value={this.state.EmailId}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Invoice/Quotation Number</Form.Label>
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Enter Invoice/Quotation Number"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Category Name</Form.Label>
                          <Form.Control
                            type="text"
                            size="lg"
                            disabled
                            placeholder=""
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Product Name</Form.Label>
                          <Form.Control
                            type="text"
                            size="lg"
                            disabled
                            placeholder=""
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            type="text"
                            size="lg"
                            disabled
                            placeholder=""
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="email"
                            size="lg"
                            placeholder="Enter Amount"
                          />
                        </Form.Group>
                        <div className="text-end">
                          <Button
                            className="mx-1"
                            size="lg"
                            style={{
                              backgroundColor: "#FF7C09",
                              color: "white",
                              fontSize: "18px",
                              fontFamily: "Helvetica",
                              width: "150px",
                            }}
                          >
                            Pay Now
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
              <Col xs={5} md={3} className=""></Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
