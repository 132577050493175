import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import CartContext from "./Components/Consumer/CartContext";
import React, { createContext, useContext, useEffect, useState } from "react";
import Navigation from "./Components/Consumer/Navigation";
import NavigationMobile from "./Components/Consumer/NavigationMobile";
import { Layout } from "./Components/Layout";
import { Home } from "./Components/Consumer/Home";
import { HomeFooter } from "./Components/Consumer/HomeFooter.js";
import { HomeGreen, HomeGreen1 } from "./Components/Consumer/HomeGreen";
import { HomeMobile } from "./Components/Consumer/HomeMobile";
import { ProductsList } from "./Components/Consumer/ProductsList";
import { ProductsListMobile } from "./Components/Consumer/ProductsListMobile";
import CartView from "./Components/Consumer/CartView";
import CheckoutView from "./Components/Consumer/CheckoutView";
import CompleteOrderView, {
  CompleteOrderKioskPhonePe,
} from "./Components/Consumer/CompleteOrderView";
import CartViewMobile from "./Components/Consumer/CartViewMobile";
import CheckoutViewMobile from "./Components/Consumer/CheckoutViewMobile";
import CompleteOrderViewMobile, {
  CompleteOrderQrcodePhonePe,
} from "./Components/Consumer/CompleteOrderViewMobile";
import UserSignUp from "./Components/Consumer/UserSignUp";
import UserLogin from "./Components/Consumer/UserLogin";
import UserProfile from "./Components/Consumer/UserProfile.js";
import UserChangePassword from "./Components/Consumer/UserChangePassword";
import UserForgotPassword from "./Components/Consumer/UserForgotPassword.js";
import UserForgotPasswordMobile from "./Components/Consumer/UserForgotPasswordMobile.js";
import UserOrders from "./Components/Consumer/UserOrders";
import UserOrdersMobile from "./Components/Consumer/UserOrdersMobile";
import UserCustomerSupport from "./Components/Consumer/UserCustomerSupport";
import {
  ProductSingleProductView1,
  ProductSingleProductViewWrapper,
} from "./Components/Consumer/ProductSingleProductView1";
import { SingleProductViewGreenArg } from "./Components/Consumer/ProductSingleProductView1";
import TermsAndConditions, { Blog } from "./Components/Consumer/Compliance";
import { PrivacyPolicy } from "./Components/Consumer/Compliance";
import { Faqs } from "./Components/Consumer/Compliance";
import { ContactUs } from "./Components/Consumer/Compliance";
import { CompleteOrderPhonePe } from "./Components/Consumer/CompleteOrderView";
import RefreshBearer from "./Components/Consumer/RefreshBearerToken.js";
import { VisitorsCount } from "./Components/Consumer/VisitorsCount.js";

import {
  ProductListViewByProductGroup,
  MainCategoryArg,
  SubCategoryArg,
  ProductListViewByMainCategory,
  ProductListViewBySubCategory,
} from "./Components/Consumer/ProductsList";

import {
  ProductListViewByMainCategoryMobile,
  ProductListViewBySubCategoryMobile,
  ProductListViewByProductGroupMobile,
} from "./Components/Consumer/ProductsListMobile";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CartProvider } from "./Components/Consumer/CartContext";
import { QrHome } from "./Components/Consumer/QrHome.js";
import { Kiosk } from "./Components/Consumer/Kiosk.js";
import { Qrcode } from "./Components/Consumer/Qrcode.js";
import { Helmet } from "react-helmet";
import { MySEO } from "./Components/Consumer/MySEO.js";
import LifeStyleAquaAuthenticator from "./Components/Consumer/LifeStyleAquaAuthenticator.js";
import { QuotePage } from "./Components/Consumer/QuotePage.js";
import { AboutUs } from "./Components/Consumer/aboutus.js";
import PayView from "./Components/Consumer/PayView.js";

function App() {
  return (
    <Container fluid className="m-0 p-0 w-100">
      <>
        {window.innerWidth <= 1100 ? (
          <CartProvider>
            <BrowserRouter>
              <MySEO></MySEO>
              {/* <Helmet>
                <title>Online Store</title>
                <meta content="Online Store" name="keywords" />
                <meta content="Online Store" name="description" />
              </Helmet> */}
              <NavigationMobile />
              <Routes>
                <Route path="/" element={<Layout />}>
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Route index element={<HomeGreen />} />
                    </>
                  ) : (
                    <>
                      <Route index element={<HomeMobile />} />
                    </>
                  )}
                  <Route path="VisitorsCount" element={<VisitorsCount />} />
                  <Route path="HomeGreen1" element={<HomeGreen1 />} />
                  <Route path="UserSignUp" element={<UserSignUp />} />
                  <Route path="UserLogin" element={<UserLogin />} />
                  <Route path="UserProfile" element={<UserProfile />} />
                  <Route
                    path="UserOrdersMobile"
                    element={<UserOrdersMobile />}
                  />
                  <Route
                    path="UserCustomerSupport"
                    element={<UserCustomerSupport />}
                  />
                  <Route
                    path="UserChangePassword"
                    element={<UserChangePassword />}
                  />
                  <Route
                    path="UserForgotPassword"
                    element={<UserForgotPasswordMobile />}
                  />
                  <Route
                    path="ProductsListMobile"
                    element={<ProductsListMobile />}
                  />
                  <Route
                    path="ProductListViewByMainCategoryMobile"
                    element={<ProductListViewByMainCategoryMobile />}
                  />
                  <Route
                    path="ProductListViewByProductGroupMobile"
                    element={<ProductListViewByProductGroupMobile />}
                  />
                  <Route
                    path="ProductListViewBySubCategoryMobile"
                    element={<ProductListViewBySubCategoryMobile />}
                  />
                  <Route
                    path="ProductSingleProductView1"
                    element={<ProductSingleProductView1 />}
                  />
                  <Route
                    path="ProductSingleProductViewWrapper"
                    element={<ProductSingleProductViewWrapper />}
                  />
                  <Route
                    path="SingleProductViewGreenArg"
                    element={<SingleProductViewGreenArg />}
                  />
                  <Route path="MainCategoryArg" element={<MainCategoryArg />} />
                  <Route path="SubCategoryArg" element={<SubCategoryArg />} />

                  <Route path="Cart" element={<CartViewMobile />} />
                  <Route path="Checkout" element={<CheckoutViewMobile />} />
                  <Route path="QuotePage" element={<QuotePage />} />
                  <Route
                    path="TermsAndConditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="ContactUs" element={<ContactUs />} />
                  <Route path="Faqs" element={<Faqs />} />
                  <Route path="Blog" element={<Blog />} />
                  <Route
                    path="CompleteOrder"
                    element={<CompleteOrderViewMobile />}
                  />
                  <Route
                    path="CompleteOrderPhonePe"
                    element={<CompleteOrderPhonePe />}
                  />
                  <Route path="Qrcode" element={<Qrcode />} />
                  <Route
                    path="CompleteOrderQrcodePhonePe"
                    element={<CompleteOrderQrcodePhonePe />}
                  />
                  <Route
                    path="LifeStyleAquaAuthenticator"
                    element={<LifeStyleAquaAuthenticator />}
                  />

                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Route path="*" element={<HomeGreen />} />
                    </>
                  ) : (
                    <>
                      <Route path="*" element={<HomeMobile />} />
                    </>
                  )}
                </Route>
              </Routes>
              <HomeFooter></HomeFooter>
            </BrowserRouter>
          </CartProvider>
        ) : (
          <CartProvider>
            <BrowserRouter className="m-0 p-0">
              {/* <Helmet>
                <title>Online Store</title>
                <meta content="Online Store" name="keywords" />
                <meta content="Online Store" name="description" />
              </Helmet> */}
              <MySEO></MySEO>
              <Navigation />
              <Routes>
                <Route path="/" element={<Layout />}>
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Route index element={<HomeGreen />} />
                    </>
                  ) : (
                    <>
                      <Route index element={<Home />} />
                    </>
                  )}

                  <Route path="VisitorsCount" element={<VisitorsCount />} />
                  <Route path="HomeGreen1" element={<HomeGreen1 />} />
                  <Route
                    path="ProductsList"
                    element={<ProductsList reload={true} />}
                  />
                  <Route
                    path="ProductSingleProductView1"
                    element={<ProductSingleProductView1 />}
                  />
                  <Route
                    path="ProductSingleProductViewWrapper"
                    element={<ProductSingleProductViewWrapper />}
                  />
                  <Route
                    path="SingleProductViewGreenArg"
                    element={<SingleProductViewGreenArg />}
                  />

                  <Route
                    path="CompleteOrderPhonePe"
                    element={<CompleteOrderPhonePe />}
                  />
                  <Route
                    path="CompleteOrderKioskPhonePe"
                    element={<CompleteOrderKioskPhonePe />}
                  />

                  <Route path="MainCategoryArg" element={<MainCategoryArg />} />
                  <Route path="SubCategoryArg" element={<SubCategoryArg />} />

                  <Route
                    path="ProductListViewByProductGroup"
                    element={<ProductListViewByProductGroup />}
                  />
                  <Route
                    path="ProductListViewByMainCategory"
                    element={<ProductListViewByMainCategory />}
                  />
                  <Route
                    path="ProductListViewBySubCategory"
                    element={<ProductListViewBySubCategory />}
                  />
                  <Route path="Cart" element={<CartView />} />
                  <Route path="Checkout" element={<CheckoutView />} />
                  <Route path="CompleteOrder" element={<CompleteOrderView />} />
                  <Route path="UserSignUp" element={<UserSignUp />} />
                  <Route path="UserLogin" element={<UserLogin />} />
                  <Route path="UserOrders" element={<UserOrders />} />
                  <Route
                    path="UserCustomerSupport"
                    element={<UserCustomerSupport />}
                  />
                  <Route
                    path="UserChangePassword"
                    element={<UserChangePassword />}
                  />
                  <Route
                    path="UserForgotPassword"
                    element={<UserForgotPassword />}
                  />
                  <Route path="UserProfile" element={<UserProfile />} />
                  <Route path="RefreshBearer" element={<RefreshBearer />} />
                  <Route
                    path="UserForgotPassword"
                    element={<UserForgotPassword />}
                  />

                  <Route path="QuotePage" element={<QuotePage />} />
                  <Route path="AboutUs" element={<AboutUs />} />

                  <Route
                    path="TermsAndConditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="ContactUs" element={<ContactUs />} />
                  <Route path="Faqs" element={<Faqs />} />
                  <Route path="Blog" element={<Blog />} />
                  <Route path="QrHome" element={<QrHome />} />
                  <Route path="Kiosk" element={<Kiosk />} />
                  <Route path="PayView" element={<PayView />} />
                  <Route
                    path="LifeStyleAquaAuthenticator"
                    element={<LifeStyleAquaAuthenticator />}
                  />

                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Route path="*" element={<HomeGreen />} />
                    </>
                  ) : (
                    <>
                      <Route path="*" element={<Home />} />
                    </>
                  )}
                </Route>
              </Routes>
              <HomeFooter></HomeFooter>
            </BrowserRouter>
          </CartProvider>
        )}
      </>
    </Container>
  );
}

export default App;
