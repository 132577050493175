import React, { createContext, useState, useEffect } from "react";
import Fuse from "fuse.js";
import axios from "axios";
import { Country, State, City } from "country-state-city";

const CartContext = createContext();
export default CartContext;

export function CartProvider({ children }) {
  // export function CartProvider() {

  const [myCart, setMyCart] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [OrderStatus, setOrderStatus] = useState("");
  const [coupon, setCoupon] = useState({
    couponCode: "",
    couponValue: 0.0,
  });
  const [deliveryPincode, setDeliveryPinCode] = useState("");

  const Environment = {
    isDev: false,
  };

  const [enterpriseLocation, setEnterpriseLocation] = useState("");
  const [refreshIncDecControl, setRefreshIncDecControl] = useState(false);
  const [visitorsCount, SetVisitorsCount] = useState("0");
  const [portalMode, setPortalMode] = useState("CustomerPortal");
  const [showUserLoginNew, setShowUserLoginNew] = useState(false);
  const [cardWidthDesktop, setCardWidthDesktop] = useState("17rem");
  const [cardWidthMobile, setCardWidthMobile] = useState("12rem");
  const [AddedToCartMsg, setAddedToCartMsg] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [storeId, setStoreId] = useState("");
  const [guestUserTypeId, setGuestUserTypeId] = useState("2");
  const [store, setStore] = useState([]);
  const [brands, setBrands] = useState([]);
  const [theme, setTheme] = useState("");
  const [mainCategories, setMainCategories] = useState([{}]);
  const [subCategories, setSubCategories] = useState([]);
  const [mainMenus, setMainMenus] = useState([]);
  const [top5Ads, setTop5Ads] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [multiCurrenciesSetting, setMultiCurrenciesSetting] = useState("");
  const [displayCounter, setDisplayCounter] = useState("");
  const [orderStatusTypes, setOrderStatusTypes] = useState([]);
  const [storeSettings, setStoreSettings] = useState("");
  const [shippingDetails, setshippingDetails] = useState({});
  const [taxDetails, settaxDetails] = useState({});
  const [wishListProductIds, setwishListProductIds] = useState([]);
  const [favProductList, setFavProductList] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [productGroupsAndProducts, setProductGroupsAndProducts] = useState([]);
  const [homePageLoaded, setHomePageLoaded] = useState(false);
  const [shopnowProduct, setShopnowProduct] = useState("");
  const [blogHtml, setBlogHtml] = useState("");
  const [selectedGreenCategory, setSelectedGreenCategory] =
    useState("sunshineTab");
  const [deliveryMethod, setDeliveryMethod] = useState("StorePickup");

  const [prods, setProducts] = useState([]);
  const [prodsAll, setProductsAll] = useState([]);
  const [ProductDataType, setProductDataType] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [reloadProductListView, setReloadProductListView] = useState("false");
  //title to show on ProductListView
  const [prodListTitle, setProdListTitle] = useState("");
  const [prodListDescription, setProdListDescription] = useState("");
  const [prodListImgFilename, setProdListImgFilename] = useState("");
  const [islanguageSelected, setIslanguageSelected] = useState(false);

  //to identify what type of products to show on ProductListView. Filters will be decided based on this type
  const [productListViewType, setProductListViewType] = useState("");
  const [productListViewMainCategoryId, setProductListViewMainCategoryId] =
    useState("");

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    storeId: "",
    emailId: "",
    mobileNo: "",
    bearerToken: "",
    loginStatus: "inactive", //active/inactive
    hideSignupLink: "", //set "true" for hide; set "" for show
  });

  const [themeTemp, setThemeTemp] = useState({
    invertLogo: "100",
    invertMenuIconOnMobile: "85",
    hideProductCount: "true",
    mainCategoryDisplayShape: "rounded",
    subCategoryAndBrandDisplayShape: "rounded-circle",
    productCardBorderColor: "black",
    maincategoryNameHeight: "1",
    maincategoryNameHeightMobile: "1",
    subcategoryNameHeight: "1",
    subcategoryNameHeightMobile: "1",
  });

  const [restaurantTheme, setRestaurantTheme] = useState("RestaurantTheme1");

  const [storeType, setStoreType] = useState({
    Basic: "1",
    Standard: "2",
    Professional: "3",
    Enterprise: "4",
  });

  const [userType, setUserType] = useState({
    Registered: 1,
    Guest: 2,
    POS: 3,
  });

  const [guestUserData, setGuestUserData] = useState([]);
  const [mainCarousel, setMainCarousel] = useState([]);

  const SetEnterpriseLocation = (value) => {
    setEnterpriseLocation(value);
  };

  const SetIslanguageSelected = (status) => {
    setIslanguageSelected(status);
  };

  const SetPortalMode = (mode) => {
    setPortalMode(mode);
  };

  const SetDeliveryMethod = (deliveryMethod) => {
    setDeliveryMethod(deliveryMethod);
  };

  const SetBlogLink = (htmlFileName) => {
    setBlogHtml(htmlFileName);
  };

  const ShowUserLoginNew = (status) => {
    setShowUserLoginNew(status);
  };

  const SetAddedToCartMsg = () => {
    setAddedToCartMsg("Added To Cart");
  };

  const SetSelectedGreenCategory = (categoryname) => {
    setSelectedGreenCategory(categoryname);
  };

  const StoreShopnowProduct = (prod) => {
    setShopnowProduct(prod);
  };

  const SetHomePageLoadedStatus = () => {
    setHomePageLoaded(true);
  };

  const GetVisitorsCount = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetVisitorsCountOnPortal/" +
        storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          SetVisitorsCount(data);
        }
      })
      .catch((error) => {});
  };

  const StoreMyIpAddress = () => {
    //fetch("https://geolocation-db.com/json/")
    fetch("https://api.ipify.org/?format=json")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //this.setState({ myClientIpAddress: data.IPv4 });
        var res = axios
          .post(
            process.env.REACT_APP_API +
              "Consumers/StoreClientIpaddress/" +
              storeId +
              "/" +
              data.ip
          )
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((error) => {
            console.log("StoreMyIpAddress error");
          });
      })
      .catch((error) => console.log(error));
  };

  const GetStoreSettings = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetStoreSetting/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          // document.title = data.storeName;
          // document.getElementsByTagName("META")[4].content =
          //   data.storeName + "Online Store :)";
          // document.getElementsByTagName("META")[5].content =
          //   data.storeName + "Online Store :)";
          // if (window.location.hostname == "shop.green.com.pg") {
          //   document.getElementsByTagName("Link")[0].href =
          //     window.location.href + "Green.ico";
          // }

          // if (data.favicon) {
          //   document.getElementsByTagName("Link")[0].href =
          //     store.storageBlobUrl +
          //     store.storageBlobContainerName +
          //     "/images/" +
          //     data.favicon +
          //     store.storageSasToken;
          // }

          setSelectedCountryCode(data.defaultCountry);
          setStoreSettings(data);
          if (data.selectedThemeType == "Product") {
            getProductTheme(data.themeName);
          } else if (data.selectedThemeType == "Custom") {
            getCustomTheme(data.customThemeName);
          }
        }
      })
      .catch((error) => {});
  };

  const SetSelectedCountryCode = (code) => {
    var tProds = JSON.parse(JSON.stringify(prodsAll));
    setSelectedCountryCode(code);
    localStorage.setItem("CountryCode", code);
    var currentCountry = multiCurrenciesSetting.filter(
      (f) => f.countryName == storeSettings.defaultCountry
    );

    var newCountry = multiCurrenciesSetting.filter(
      (f) => f.countryName == code
    );
    if (newCountry.length == 1 && currentCountry.length == 1) {
      // 1. convert to default currency
      if (currentCountry[0].exchangeRate != 1)
        tProds.map(
          (m) => (m.mrp = m.mrp * (1 / currentCountry[0].exchangeRate))
        );
      // 2. convert to new currency
      if (newCountry[0].exchangeRate != 1)
        tProds.map((m) => (m.mrp = m.mrp * newCountry[0].exchangeRate));
      setProductsAll(JSON.parse(JSON.stringify(tProds)));
      setProducts(JSON.parse(JSON.stringify(tProds)));

      storeSettings.defaultCountry = code;
      storeSettings.defaultCurrency = newCountry[0].currencyCode;
      storeSettings.defaultCountryCode = newCountry[0].defaultCountryCode;
      storeSettings.defaultLocale = newCountry[0].defaultLocale;
      storeSettings.defaultState = newCountry[0].defaultState;
      setStoreSettings(JSON.parse(JSON.stringify(storeSettings)));
      UpdateReloadProductListView("true");

      // setProductsAll((state) => {
      //   UpdateReloadProductListView("true");
      // });
    }
  };

  const GetMultiCurrencys = () => {
    fetch(
      process.env.REACT_APP_API + "StoreManager/GetMultiCurrencys/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setMultiCurrenciesSetting(data);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const GetDisplayCounter = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetClientDisplayCounters/" +
        storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setDisplayCounter(data);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const GetProductDataType = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API + "StoreManager/GetProductDataType/" + storeId
      )
      .then((response) => {
        if (response.status === 200) {
          setProductDataType(response.data);
        }
      })
      .catch((error) => {
        console.error("GetProductDataType - There was an error! ", error);
      });
  };

  const getProductTheme = (themeName) => {
    if (themeName == "") {
      alert("ThemeName is not set!");
      return;
    }
    fetch(process.env.REACT_APP_API + "Consumers/GetProductTheme/" + themeName)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setTheme(data);
        }
      })
      .catch((error) => {
        console.error("GetTheme: There was an error!", error);
      });
  };
  const getCustomTheme = (themeName) => {
    if (themeName == "") {
      alert("ThemeName is not set!");
      return;
    }
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetCustomTheme/" +
        storeId +
        "," +
        themeName
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setTheme(data);
        }
      })
      .catch((error) => {
        console.error("GetTheme: There was an error!", error);
      });
  };

  const getShippingDetails = () => {
    fetch(
      process.env.REACT_APP_API + "StoreManager/GetShippingDetails/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setshippingDetails(data);
        }
      })
      .catch((error) => {
        console.error("GetShippingDetails!", error);
      });
  };
  const getTaxDetails = () => {
    fetch(process.env.REACT_APP_API + "StoreManager/GetTaxDetails/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          settaxDetails(data);
        }
      })
      .catch((error) => {
        console.error("GetShippingDetails!", error);
      });
  };

  const getBrands = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetBrands/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setBrands(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const GetDistance = (source, dest) => {
    var res = axios
      .get(
        "https://maps.googleapis.com/maps/api/distancematrix/xml?origins=12413%20destinations=12211%20key=AIzaSyD82tDtfcW-q4RyqqVSYoAg_ueZtBE3jy4"
      )
      .then((response) => {
        if (response.status == 200) {
          console.log(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.error("There was an error! ", error);
      });
  };

  const GetMainCarousel = (targetDevice) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetCarousel/" +
          storeId +
          "," +
          targetDevice
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          setMainCarousel(response.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("GetCarousel - There was an error! ", error);
        // alert("Error GetCarousel:" + error);
      });
  };

  const GetDefaultCurrency = () => {
    if (storeSettings.defaultCurrency == "INR") {
      return "₹";
    } else if (storeSettings.defaultCurrency == "USD") {
      return "$";
    }
  };

  const GetOrderStatusTypes = () => {
    var res = axios
      .get(process.env.REACT_APP_API + "StoreManager/GetOrderStatusTypes/")
      .then((response) => {
        if (response.status === 200) {
          setOrderStatusTypes(response.data);
        }
      })
      .catch((error) => {
        console.error("GetOrderStatusTypes - There was an error! ", error);
      });
  };

  const GetMainMenus = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetMainMenus/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setMainMenus(data);
        }
      })
      .catch((error) => {
        alert("GetMainMenus:: " + error);
      });
  };
  const GetTop5Ads = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetTop5Ads/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setTop5Ads(data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)));
        }
      })
      .catch((error) => {
        alert("GetMainMenus:: " + error);
      });
  };

  const AddToWishList = (prod) => {
    //check if product items exist, go for update
    const indexItem = wishListProductIds.findIndex(
      (item) => item.prodId === prod.id
    );

    if (indexItem != -1) {
      //already added...do nothing..
      DeleteProductOnWishList(prod.id);
    } else {
      // add
      setwishListProductIds([...wishListProductIds, { prodId: prod.id }]);
    }

    //this is a callback. store the fav in local store
    setwishListProductIds((state) => {
      //todo: enable
      localStorage.setItem("localstoragefavlist", JSON.stringify(state));
      return state;
    });
  };

  const SetCookie = () => {
    localStorage.setItem("CookieAccepted", true);
  };
  const GetCookie = () => {
    var cookie = localStorage.getItem("CookieAccepted");
    if (cookie == "true") return true;
    return false;
  };

  const DeleteProductOnWishList = (id) => {
    setwishListProductIds(
      wishListProductIds.filter((productitem) => id != productitem.prodId)
    );

    //this is a callback. store the cart in local store
    setwishListProductIds((state) => {
      //todo: enable
      localStorage.setItem("localstoragefavlist", JSON.stringify(state));
      return state;
    });
  };

  // const StoreOrderSummary = (data) => {
  //   setOrderSummary(data);
  // };
  const StoreGuestUserData = (data) => {
    setGuestUserData(data);
  };
  const storeOrderInformation = (data) => {
    setOrderData(data);
  };
  const UpdateOrderStatus = (status) => {
    setOrderStatus(status);
  };

  const GetProductGroups = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetProductGroups/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setProductGroups(data);
        }
      })
      .catch((error) => {
        alert("GetProductGroups:: " + error);
      });
  };

  const GetProductGroupsAndProducts = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetProductGroupsDetail/" + storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setProductGroupsAndProducts(data);
      })
      .catch((error) => {
        alert("GetProductGroupsAndProducts:: " + error);
      });
  };

  const searchOptions = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ["title", "author.firstName"],
  };

  const GetSearchResult = (pattenText) => {
    //setSearchData(options);
    const fuse = new Fuse(prods, searchOptions);
    setSearchData(fuse.search(pattenText));
  };

  const storePreviousPage = (value) => {
    setPrevPage(value);
  };

  const ClearCartAndOrderDetails = () => {
    setMyCart([]);
    setCoupon({ couponCode: "", couponValue: 0 });
    localStorage.removeItem("localstoragecartitems");
  };

  const SetStore = (store) => {
    setStore(store);
  };
  const GetStore = () => {
    fetch(process.env.REACT_APP_API + "Sales/GetStore/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          setStore(data);
        }
      })
      .catch((error) => {
        alert("GetStores Error:: " + error);
      });
  };

  const storeUserData = (data) => {
    setUserData({
      id: data.id,
      storeId: data.storeId,
      firstName: data.firstName,
      lastName: data.lastName,
      mobileNo: data.mobileNo,
      emailId: data.emailId,
      password: data.password,
      address: data.address,
      landmark: data.landmark,
      city: data.city,
      state: data.state,
      country: data.country,
      pinCode: data.pinCode,
      customerUserTypeId: data.customerUserTypeId,
      bearerToken: data.bearerToken,
      loginStatus: "active",
      hideSignupLink: "true", //set "true" for hide; set "" for show
    });

    //this is a callback. store the cart in local store
    setUserData((state) => {
      //todo: enable
      localStorage.setItem("localstorageUserData", JSON.stringify(state));
      return state;
    });
  };
  const clearUserData = () => {
    setUserData({
      firstName: "",
      lastName: "",
      emailId: "",
      password: "",
      mobileNo: "",
      bearerToken: "",
      loginStatus: "inactive",
      hideSignupLink: "", //set "true" for hide; set "" for show
    });

    //this is a callback. store the cart in local store
    setUserData((state) => {
      //todo: enable
      localStorage.setItem("localstorageUserData", JSON.stringify(state));
      return state;
    });
  };

  const getMainCategories = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetMainCategory/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setMainCategories(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("getMainCategories err:" + error);
      });
  };

  const getSubCategories = () => {
    fetch(process.env.REACT_APP_API + "Consumers/GetSubCategory/" + storeId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else setSubCategories(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("getSubCategories err:" + error);
      });
  };

  const GetAllProducts = () => {
    var sourceStr =
      portalMode == "CustomerPortal"
        ? "/OnlinePortal"
        : portalMode == "Kiosk"
        ? "/Kiosk"
        : portalMode == "Qrcode"
        ? "/Qrcode"
        : "";
    fetch(
      process.env.REACT_APP_API + "Consumers/GetProducts/" + storeId + sourceStr
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          // for (var i = 0; i < data.length; i++) {
          //   data[i].mrp = Math.round(
          //     data[i].mrp +
          //       (data[i].mrp * data[i].homeDeliveryChargeInPercentage) / 100,
          //     0
          //   );
          // }

          setProducts(data);
          setProductsAll(data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const GetProductsByMainCategoryId = (MainCategoryId) => {
    var category = mainCategories.find((f) => f.id == MainCategoryId);
    if (category != undefined) {
      setProdListTitle(category.name);
      setProdListDescription(category.description);
      setProdListImgFilename(category.adImgFileName);
      setProductListViewType("MainCategory");
      setProductListViewMainCategoryId(MainCategoryId);
    }
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter(
        (productitem) => MainCategoryId == productitem.mainCategoryId
      )
    );
  };
  const GetProductsBySubCategoryId = (CategoryId) => {
    var category = subCategories.find((f) => f.id == CategoryId);
    if (category != undefined) {
      setProdListTitle(category.name);
      setProductListViewType("SubCategory");
    }
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter((productitem) => CategoryId == productitem.subCategoryId)
    );
  };
  const GetProductsByBrandId = (brandId) => {
    var brand = brands.find((f) => f.id == brandId);
    if (brand != undefined) {
      setProdListTitle(brand.brandName);
      setProductListViewType("Brand");
    }
    var tmpProds = prodsAll;
    setProducts(
      tmpProds.filter((productitem) => brandId == productitem.brandId)
    );
  };
  // const GetProductsByProductgroupId = (id) => {
  //   var category = productGroupsAndProducts.find((f) => f.id == id);
  //   if (category != undefined) {
  //     setProdListTitle(category.name);
  //     setProductListViewType("ProductGroup");
  //   }

  //   var tmpProds = productGroupsAndProducts.filter(
  //     (productgroup) => id == productgroup.id
  //   );

  //   var prodarray = [];
  //   tmpProds.map((t) => prodarray.push(t.prod));
  //   setProducts(prodarray);
  // };

  const GetProductsByProductgroupId = (id) => {
    var category = productGroups.find((f) => f.id == id);
    if (category != undefined) {
      setProdListTitle(category.name);
      setProdListImgFilename(category.adsImgFileName);
      setProductListViewType("ProductGroup");
    }

    var prodarray = [];
    category.productGroupAndProductsMaps.map((m) => {
      var pd = prodsAll.find((f) => f.id == m.productId);
      if (pd != undefined) prodarray.push(pd);
    });
    setProducts(prodarray);
  };

  const UpdateReloadProductListView = (val) => {
    setReloadProductListView(val);
  };

  const GetProducts = (MainCategoryId, SubCategoryId, filtertext) => {};

  const SetRefreshIncDecControl = (value) => {
    setRefreshIncDecControl(value);
  };

  //create your forceUpdate hook
  function useForceUpdate() {
    //setRefreshIncDecControl(true);
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
  }

  const forceUpdateCartView = useForceUpdate();

  const addToMyCart = (cartitem) => {
    //add if first item
    if (myCart === "") {
      setMyCart([...myCart, cartitem]);
      setAddedToCartMsg("Added To Cart");
      return;
    }
    //check if product items exist, go for update
    const indexItem = myCart.findIndex(
      (item) => item.prod.id == cartitem.prod.id
    );
    if (indexItem != -1 && cartitem.prod.productVariantType == 1) {
    } else if (indexItem != -1 && cartitem.prod.productVariantType == 2) {
      var duplicateProducts = myCart.filter(
        (f) => f.prod.name == cartitem.prod.name
      );
      if (duplicateProducts.length > 0) {
      } else {
        setMyCart([...myCart, cartitem]);
      }
    } else {
      // add
      setMyCart([...myCart, cartitem]);
      setAddedToCartMsg("Added To Cart");
    }

    //this is a callback. store the cart in local store
    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const ClearAddedToCartMsg = () => {
    setAddedToCartMsg("");
  };

  const deleteItemOnMyCart = (prod) => {
    if (prod.productVariantType == 2) {
      var duplicateProducts = myCart.filter((f) => f.prod.name == prod.name);
      if (duplicateProducts.length > 1) {
        setMyCart(
          myCart.filter((productitem) => prod.name != productitem.prod.name)
        );
      } else {
        setMyCart(
          myCart.filter((productitem) => prod.name != productitem.prod.name)
        );
      }
    } else {
      setMyCart(myCart.filter((productitem) => prod.id != productitem.prod.id));
    }

    //this is a callback. store the cart in local store
    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };
  const deleteItemsOnMyCart = (items) => {
    var tempMyCart = myCart;
    items.map((item) => {
      tempMyCart = tempMyCart.filter(
        (productitem) => item.prod.id != productitem.prod.id
      );
    });
    setMyCart(tempMyCart);

    //this is a callback. store the cart in local store
    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const UpdateQtyToCart = (prod, qty) => {
    if (qty > 0) {
      myCart.map((item) => {
        if (item.prod.id == prod.id) {
          item.qty = qty;
        }
      });
      setMyCart(myCart);
    }
    if (qty == 0) {
      deleteItemOnMyCart(prod);
    }

    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const SetStateOfMyCart = (cart) => {
    setMyCart(cart);

    setMyCart((state) => {
      //todo: enable
      localStorage.setItem("localstoragecartitems", JSON.stringify(state));
      return state;
    });
  };

  const ClearCookies = () => {
    //ClearCookies
    localStorage.setItem("localstoragecartitems", "");
    localStorage.setItem("localstorageUserData", "");
  };
  const RefreshBearerTokenWithMobileNo = (userData) => {
    if (userData !== "" && userData.loginStatus !== "active") {
      ShowUserLoginNew(true);
      return;
    }
    if (
      userData !== "" &&
      userData.loginStatus === "active" &&
      userData.id === undefined
    ) {
      ShowUserLoginNew(true);
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: userData.storeId,
        mobileNo: userData.mobileNo,
        emailId: userData.emailId,
      }),
    };

    fetch(
      process.env.REACT_APP_API + "Consumers/LoginWithPhoneNumber",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          clearUserData();
          return;
        }
        storeUserData(data);
        console.log("storeUserData(data) success");
      })
      .catch((error) => {
        console.error("RefreshBearerToken: There was an error!", error);
        //alert("RefreshBearerToken err:" + error);
      });
  };

  const ValidateLogin = (mobileNo, emailId, storeId) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: storeId,
        mobileNo: mobileNo,
        emailId: emailId,
      }),
    };
    fetch(
      process.env.REACT_APP_API + "Consumers/LoginWithPhoneNumber",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404 || data.status == 400) {
          storeUserData({
            firstName: "",
            lastName: "",
            emailId: "",
            mobileNo: "",
            bearerToken: "",
            loginStatus: "inactive", //active/inactive
            hideSignupLink: "", //set "true" for hide; set "" for show
          });
          return;
        }
        storeUserData(data);
      })
      .catch((error) => {
        return;
      });
  };

  //when page is reloaded, restore the cart from cookie
  //todo: clear the cookie when localstorage is older than a week/month
  useEffect(() => {
    let v1 = localStorage.getItem("localstoragecartitems");

    if (v1 !== null && v1 !== `""` && v1 !== ``) setMyCart(JSON.parse(v1));
    let wishlist = localStorage.getItem("localstoragefavlist");

    if (wishlist !== null && wishlist !== `""` && wishlist !== ``)
      setwishListProductIds(JSON.parse(wishlist));

    v1 = localStorage.getItem("localstorageUserData");
    if (v1 !== null && v1 !== `""` && v1 !== ``) {
      setUserData(JSON.parse(v1));

      //re-login when portal is open
      setUserData((state) => {
        if (
          (state.mobileNo != "" && state.mobileNo != undefined) ||
          (state.emailId != "" && state.emailId != undefined)
        ) {
          //if (state.emailId != "") {
          // ValidateLogin(state.emailId, state.password, state.storeId);
          ValidateLogin(state.mobileNo, state.emailId, state.storeId);
        }
        return state;
      });
    }
  }, []);

  const ClearAllCookies = () => {
    //1. clear Cart Items
    localStorage.setItem("localstoragecartitems", null);
  };

  const getTotalQtyInCart = (myCart) => {
    if (myCart == undefined) return 0;
    var totqty = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totqty = totqty + Number(item.qty);
      });
    }
    return totqty;
  };
  const getTotalSavingsFromCart = (myCart) => {
    var totsav = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totsav =
          totsav +
          (Number(item.prod.mrp) *
            Number(item.qty) *
            Number(item.prod.discount)) /
            100;
      });
    }
    return Number(totsav);
  };
  const getSubTotalPriceInCart = (myCart) => {
    var totprice = 0;
    if (myCart !== null && (myCart !== `""`) & (myCart !== ``)) {
      myCart.map((item) => {
        totprice =
          totprice +
          Number(item.qty) *
            (Number(item.prod.mrp) -
              (Number(item.prod.mrp) * Number(item.prod.discount)) / 100);
      });
    }
    return Number(totprice);
  };

  const CheckFreeDeliveryPincodes = (pincode) => {
    if (shippingDetails.freeDeliveryPincodes) {
      var pincodes = String(
        shippingDetails.freeDeliveryPincodes.replace(/ /g, "")
      ).split(",");
      if (pincodes.length > 0) {
        var result = false;

        for (var i = 0; i < pincodes.length; i++) {
          var pincodeRange = pincodes[i].split("-");
          if (pincodeRange.length == 2) {
            if (pincodeRange[0] <= pincode && pincodeRange[1] >= pincode) {
              return true;
            }
          } else if (pincodes[i] == pincode) {
            return true;
          }
        }
        return false;
      }
    }
    return false;
  };

  const SetDeliveryPinCode = (pincode) => {
    setDeliveryPinCode(pincode);
  };

  const IsDeliveryCostIncludedInAllProductsInCart = () => {
    var result = myCart.filter((f) => f.prod.deliveryCostIncluded == false);
    if (result.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const getDeliveryAmount = () => {
    if (myCart.length > 0) {
      if (
        shippingDetails.freeDelivery == true ||
        deliveryMethod == "StorePickup"
      ) {
        return 0;
      } else if (
        shippingDetails.deliveryCharges == true &&
        IsDeliveryCostIncludedInAllProductsInCart() == false
      ) {
        if (CheckFreeDeliveryPincodes(deliveryPincode) == true) return 0;
        else if (
          getSubTotalPriceInCart(myCart) > shippingDetails.freeDeliveryAbove
        ) {
          return 0;
        } else {
          return shippingDetails.deliveryChargePerOrder;
        }
      } else return 0;
    } else return 0;
  };

  const getTaxAmount = () => {
    if (taxDetails.inclusiveOfTax == true) return 0;
    return (getTotalAmountWithoutTax() * taxDetails.taxPercentage) / 100;
  };

  const getTotalAmountWithoutTax = () => {
    return (
      getSubTotalPriceInCart(myCart) +
      getDeliveryAmount() -
      Number(coupon.couponValue)
    );
  };

  const getTotalAmountWithTax = () => {
    return getTotalAmountWithoutTax() + getTaxAmount();
  };

  const StoreCouponDetails = (couponCode, couponValue) => {
    setCoupon({ couponCode: couponCode, couponValue: couponValue });
  };
  const IsVideo = (filename) => {
    if (filename) {
      var f = filename.split(".").pop();
      if (f == "mp4") return true;
    }
    return false;
  };

  const GetCountryByCode = (code) => {
    var r = Country.getCountryByCode(code);
    if (r != null) return r.name;
    else return "India";
  };

  const GetInDateFormat = (D) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = D.getDate();

    const monthIndex = D.getMonth();
    const monthName = monthNames[monthIndex];

    const year = D.getFullYear();

    return `${day}-${monthName}-${year}`;
  };

  const updateMyCart = (cartitem) => {};

  return (
    <CartContext.Provider
      value={{
        storeId,
        storeType,
        userType,
        portalMode,
        guestUserTypeId,
        searchData,
        myCart,
        mainCategories,
        subCategories,
        productGroups,
        productGroupsAndProducts,
        homePageLoaded,
        displayCounter,
        prods,
        prodsAll,
        userData,
        ProductDataType,
        prevPage,
        orderData,
        OrderStatus,
        guestUserData,
        AddedToCartMsg,
        reloadProductListView,
        prodListTitle,
        prodListDescription,
        prodListImgFilename,
        productListViewType,
        productListViewMainCategoryId,
        wishListProductIds,
        mainMenus,
        top5Ads,
        mainCarousel,
        orderStatusTypes,
        cardWidthDesktop,
        cardWidthMobile,
        multiCurrenciesSetting,
        store,
        selectedCountryCode,
        brands,
        visitorsCount,
        theme,
        themeTemp,
        storeSettings,
        shippingDetails,
        taxDetails,
        coupon,
        showUserLoginNew,
        Environment,
        shopnowProduct,
        selectedGreenCategory,
        blogHtml,
        deliveryMethod,
        refreshIncDecControl,
        deliveryPincode,
        islanguageSelected,
        enterpriseLocation,
        restaurantTheme,
        GetStore,
        SetStore,
        RefreshBearerTokenWithMobileNo,
        storeUserData,
        clearUserData,
        addToMyCart,
        deleteItemOnMyCart,
        deleteItemsOnMyCart,
        forceUpdateCartView,
        getTotalQtyInCart,
        getSubTotalPriceInCart,
        getTotalSavingsFromCart,
        getMainCategories,
        getSubCategories,
        GetProductGroups,
        GetAllProducts,
        GetMultiCurrencys,
        GetProductsByMainCategoryId,
        GetProductsBySubCategoryId,
        GetProductsByProductgroupId,
        GetProducts,
        GetVisitorsCount,
        GetSearchResult,
        ClearCartAndOrderDetails,
        storePreviousPage,
        StoreGuestUserData,
        UpdateOrderStatus,
        storeOrderInformation,
        ClearAddedToCartMsg,
        UpdateReloadProductListView,
        AddToWishList,
        DeleteProductOnWishList,
        setStoreId,
        GetMainMenus,
        GetTop5Ads,
        GetMainCarousel,
        GetOrderStatusTypes,
        setProducts,
        GetProductGroupsAndProducts,
        getBrands,
        GetStoreSettings,
        getShippingDetails,
        getTaxDetails,
        getDeliveryAmount,
        getTaxAmount,
        getTotalAmountWithoutTax,
        getTotalAmountWithTax,
        StoreCouponDetails,
        SetStateOfMyCart,
        GetProductsByBrandId,
        ShowUserLoginNew,
        GetDefaultCurrency,
        SetHomePageLoadedStatus,
        UpdateQtyToCart,
        GetDistance,
        StoreShopnowProduct,
        GetProductDataType,
        SetSelectedGreenCategory,
        GetInDateFormat,
        GetDisplayCounter,
        SetPortalMode,
        StoreMyIpAddress,
        SetBlogLink,
        IsVideo,
        SetDeliveryMethod,
        SetRefreshIncDecControl,
        SetCookie,
        GetCookie,
        SetDeliveryPinCode,
        GetCountryByCode,
        SetSelectedCountryCode,
        SetIslanguageSelected,
        SetEnterpriseLocation,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
