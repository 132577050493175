import React, { Component, useState } from "react";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { LoadSpinner } from "./LoadSpinner";
import Swal from "sweetalert2";

import { Button, Row, Col, Form, FloatingLabel, Stack } from "react-bootstrap";

export class QuotePage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      termsandconditions: false,
      loadSpinnerOpen: false,
      requestSubmited: false,
      quote: {
        customerName: "",
        contactNumber: "",
        emailId: "",
        categoryName: "",
        productName: "",
        qty: 0,
        purposeOfProcurement: "",
        preferredLanguage: "",
        deliveryTimeline: "",
        detailedRequirement: "",
      },
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  GetSelectedMainCategoryId = () => {
    var r = this.context.mainCategories.filter(
      (f) => f.name == this.state.quote.categoryName
    );

    if (r.length > 0) return r[0].id;

    return "";
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "termsandconditions":
        this.setState({ termsandconditions: e.target.checked });
        return;
      case "customerName":
        this.state.quote.customerName = value;
        break;
      case "contactNumber":
        this.state.quote.contactNumber = value;
        break;
      case "emailId":
        this.state.quote.emailId = value;
        break;
      case "categoryName":
        this.state.quote.categoryName = value;
        break;
      case "productName":
        this.state.quote.productName = value;
        break;
      case "qty":
        if (value > 0) this.state.quote.qty = value;
        break;
      case "purposeOfProcurement":
        this.state.quote.purposeOfProcurement = value;
        break;
      case "preferredLanguage":
        this.state.quote.preferredLanguage = value;
        break;
      case "deliveryTimeline":
        this.state.quote.deliveryTimeline = value;
        break;
      case "detailedRequirement":
        this.state.quote.detailedRequirement = value;
        break;
    }
    this.setState({ quote: this.state.quote });
  };

  SubmitQuoteRequest = () => {
    this.ShowLoadSpinner();

    var str = {
      storeId: this.context.storeId,
      customerName: this.state.quote.customerName,
      contactNumber: this.state.quote.contactNumber,
      emailId: this.state.quote.emailId,
      categoryName: this.state.quote.categoryName,
      productName: this.state.quote.productName,
      qty: this.state.quote.qty,
      purposeOfProcurement: this.state.quote.purposeOfProcurement,
      preferredLanguage: this.state.quote.preferredLanguage,
      deliveryTimeline: this.state.quote.deliveryTimeline,
      detailedRequirement: this.state.quote.detailedRequirement,

      amountReceived: 0,
      quoteStatus: 1, //new request
      paymentStatus: 2, //1.Paid 2.Unpaid 3.PartiallyPaid
      quotationTotalAmount: 0,
    };

    axios
      .post(process.env.REACT_APP_API + "Consumers/SubmitQuoteRequest", str)
      .then((response) => {
        if (response.status === 200) {
          this.HideLoadSpinner();
          Swal.fire({
            title: "Information!",
            confirmButtonColor: "#23B14D",
            text: "Thank you. We have received your request. Our Team would get in touch with you shortly! ",
            confirmButtonText: "Ok",
          }).then((result) => {
            this.setState({ requestSubmited: true });
          });
        } else {
          this.HideLoadSpinner();
          Swal.fire({
            title: "Failed!",
            confirmButtonColor: "#23B14D",
            text: "Submit failed. Please try after sometime!",
            confirmButtonText: "Ok",
          }).then((result) => {});
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        Swal.fire({
          title: "Failed!",
          confirmButtonColor: "#23B14D",
          text: "Submit failed. Please try after sometime!",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.SubmitQuoteRequest();
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.state.requestSubmited == true) {
      return <Navigate to="/home" />;
    }
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <img
          className="d-block image-blurred-edge"
          src={
            this.context.store.storageBlobUrl +
            this.context.store.storageBlobContainerName +
            "/images/" +
            "request-quote-banner.jpg" +
            this.context.store.storageSasToken
          }
          alt="First slide"
          style={{
            width: "100%",
            cursor: "pointer",
            // objectFit: "contain",
            height: "10cm",
          }}
        />
        <div
          className=" mx-5 my-3 border-0 CornersRounded"
          style={{
            marginLeft: this.context.theme.homeMarginSpace + "cm",
            marginRight: this.context.theme.homeMarginSpace + "cm",
            backgroundColor: this.context.theme.homepageBackgroundColor,
            paddingLeft: "100px",
            paddingRight: "100px",
          }}
        >
          <h4 style={{ textAlign: "justify" }}>
            Welcome and start your procurement step here by requesting a quote.
            At BagFactry.com, we've simplified the sourcing process. By
            requesting a quote, our dedicated team would be able to provide the
            best quotation at competitive price. Trust Bagfactry.com to
            transform your procurement journey and provide a seamless, reliale
            solution. Your quote request is a ticket to a future of effortless
            and successful sourcing.
          </h4>
          <Form onSubmit={this.handleSubmit}>
            <div
              className=" p-3 border "
              style={{
                backgroundColor: this.context.theme.homepageBackgroundColor,
                paddingLeft: "100px",
                paddingRight: "100px",
              }}
            >
              <h1 className="text-center fw-bold">
                Request Form for Quotation
              </h1>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Your Name"
                    className="mb-4"
                  >
                    <Form.Control
                      type="text"
                      size="lg"
                      placeholder=""
                      required
                      name="customerName"
                      value={this.state.quote.customerName}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Your Contact Number"
                    className="mb-4"
                  >
                    <Form.Control
                      type="text"
                      size="lg"
                      required
                      placeholder=""
                      name="contactNumber"
                      value={this.state.quote.contactNumber}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Your Email"
                    className="mb-4"
                  >
                    <Form.Control
                      type="email"
                      size="lg"
                      required
                      placeholder=""
                      name="emailId"
                      value={this.state.quote.emailId}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Select Category"
                    className="mb-4"
                  >
                    <Form.Select
                      type="text"
                      size="lg"
                      required
                      placeholder=""
                      name="categoryName"
                      value={this.state.quote.categoryName}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value=""></option>
                      {this.context.mainCategories.map((maincategory) => (
                        <>
                          <option value={maincategory.name}>
                            {maincategory.name}
                          </option>
                        </>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Select Product"
                    className="mb-4"
                  >
                    <Form.Select
                      type="text"
                      size="lg"
                      placeholder=""
                      required
                      name="productName"
                      value={this.state.quote.productName}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value=""></option>
                      {this.context.prods
                        .filter(
                          (f) =>
                            f.mainCategoryId == this.GetSelectedMainCategoryId()
                        )
                        .map((prod) => (
                          <>
                            <option value={prod.name}>{prod.name}</option>
                          </>
                        ))}
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Enter Quantity"
                    className="mb-4"
                  >
                    <Form.Control
                      type="number"
                      size="lg"
                      required
                      placeholder=""
                      name="qty"
                      value={this.state.quote.qty}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Select Purpose of procurement"
                    className="mb-4"
                  >
                    <Form.Select
                      type="text"
                      size="lg"
                      required
                      placeholder=""
                      name="purposeOfProcurement"
                      value={this.state.quote.purposeOfProcurement}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value=""></option>
                      <option value="Personal">Personal</option>
                      <option value="Online Retailing">Online Retailing</option>
                      <option value="Offline Retailing">
                        Offline Retailing
                      </option>
                      <option value="Wholesale">Wholesale</option>
                      <option value="Event">Event</option>
                      <option value="Corporate">Corporate</option>
                      <option value="Education Institution">
                        Education Institution
                      </option>
                      <option value="NGO-Non Profit Organization">
                        NGO-Non Profit Organization
                      </option>
                      <option value="Government">Government</option>
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Select Preferred Language"
                    className="mb-4"
                  >
                    <Form.Select
                      type="text"
                      size="lg"
                      required
                      placeholder=""
                      name="preferredLanguage"
                      value={this.state.quote.preferredLanguage}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value=""></option>
                      <option value="English">English</option>
                      <option value="Hindi">Hindi</option>
                      <option value="Kannada">Kannada</option>
                      <option value="Tamil">Tamil</option>
                      <option value="Telugu">Telugu</option>
                      <option value="Malayalam">Malayalam</option>
                    </Form.Select>
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="floatingInput"
                    label="Select Delivrey Timeline"
                    className="mb-4"
                  >
                    <Form.Select
                      type="text"
                      size="lg"
                      required
                      placeholder=""
                      name="deliveryTimeline"
                      value={this.state.quote.deliveryTimeline}
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value=""></option>
                      <option value="5 days">5 days</option>
                      <option value="10 days">10 days</option>
                      <option value="20 days">20 days</option>
                      <option value="30 days">30 days</option>
                      <option value="45 days">45 days</option>
                      <option value="60 days">60 days</option>
                      <option value="90 days">90 days</option>
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Please share more details of your requirements"
                    className="mb-4"
                  >
                    <Form.Control
                      as="textarea"
                      size="lg"
                      rows={8}
                      maxLength={499}
                      style={{ height: "170px" }}
                      name="detailedRequirement"
                      value={this.state.quote.detailedRequirement}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Form.Group as={Row} className="mb-1" controlId="">
                <Stack direction="horizontal">
                  <Form.Check
                    name="termsandconditions"
                    required
                    checked={this.state.termsandconditions == true}
                    label=""
                    onChange={(e) => this.handleInputChange(e)}
                  />
                  I agree to the User Agreement of BagFactry.com
                </Stack>
              </Form.Group>
              <div className="  text-center">
                <Button
                  className=" border-0 buttonwithshadow mx-0 text-center px-3"
                  type="submit"
                  style={{
                    backgroundColor: "#FF7C09",
                    color: "white",
                    fontSize: "22px",
                    fontFamily: "Helvetica",
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
